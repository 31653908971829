import logo from './logo.svg';
import './App.css';
import Home from './Components/Home/Home';
import Login from './Components/Account/Login/Login';
import Layout from './Components/Layout/Layout';
import { Navigate, RouterProvider, createBrowserRouter, createHashRouter } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import AddUser from './Components/Account/AddUser/AddUser';
import AllUsers from './Components/Account/AllUsers/AllUsers';
import ManageUser from './Components/Account/ManageUser/ManageUser';
import AllTemplates from './Components/Templates/AllTemplates/AllTemplates';
import ManageTemplate from './Components/Templates/ManageTemplate/ManageTemplate';
import AddTemplate from './Components/Template/AddTemplate/AddTemplate';
import Report from './Components/Report/Report';
import SMSReport from './Components/Report/SMSReport';
import ReportForUser from './Components/Report/ReportForUser';

function App() {
  const [crrUser, setCrrUser] = useState(null);
  const [role, setrole] = useState(null);
  function getUserData() {
    if(localStorage.getItem("Gtkn")!=null){
      const user = jwtDecode(localStorage.getItem("Gtkn"));
     // console.log(user);
      setCrrUser(user);
      const userRole = user["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
      setrole(userRole);
     // console.log(userRole); 
    }
   
  }
  function deleteUser() {
    setCrrUser(null);
  }
  function ProtectedRoute({children}) {
    if(localStorage.getItem("Gtkn")==null){
      return <Navigate to='/login'/>
    }else{
      return children;
    }
  }
  function ProtectedRouteForAdmin({children}) {
    if(role!="Admin"){
      return <Navigate to='/login'/>
    }else{
      return children;
    }
  }
  function reload() {
   
    if(localStorage.getItem("Gtkn")!=null&&crrUser==null){
      getUserData();
    }
    console.log("hello reloading...");
  }
  useEffect(function(){
     reload();
   
    getUserData();
  
  },[])
  const router = createHashRouter([
    {
      path: "",
      element: <Layout deleteUser={deleteUser} user={crrUser} role={role}/>,
      children: [
        { path: "/home", element: <ProtectedRoute><Home role={role}/> </ProtectedRoute>},
        { path: "", element:<ProtectedRoute><Home role={role}/> </ProtectedRoute> },
        {
          path: "login",
          element: <Login crruser={crrUser} getUserData={getUserData} />,
        },
        {
          path: "AddUser",
          element: <ProtectedRouteForAdmin><AddUser></AddUser></ProtectedRouteForAdmin>,
        },
        {
          path: "AllUsers",
          element: <ProtectedRouteForAdmin><AllUsers></AllUsers></ProtectedRouteForAdmin>,
        }, {
          path: "ManageUser/:id",
          element: <ProtectedRouteForAdmin><ManageUser></ManageUser></ProtectedRouteForAdmin>,
        },
        {
          path: "AllTemplates",
          element: <ProtectedRouteForAdmin><AllTemplates></AllTemplates></ProtectedRouteForAdmin>,
        },
        {
          path: "ManageTemplate/:Id",
          element: <ProtectedRouteForAdmin><ManageTemplate></ManageTemplate></ProtectedRouteForAdmin>,
        },
        {
          path: "AddTemplate",
          element: <ProtectedRouteForAdmin><AddTemplate></AddTemplate></ProtectedRouteForAdmin>,
        },
        {
          path: "Report",
          element:<ProtectedRouteForAdmin><Report></Report></ProtectedRouteForAdmin>,
        },{
          path: "SMSReport",
          element:<ProtectedRouteForAdmin><SMSReport></SMSReport></ProtectedRouteForAdmin>,
        },
        {
          path: "ReportForUser",
          element:<ReportForUser></ReportForUser>,
        },
        {
          path: "*",
          element: (
            <>
              "not found"
            </>
          ),
        },
      ],
    },
  ]);
  return (
    <>
     <RouterProvider router={router} />
    </>
  );
}

export default App;
