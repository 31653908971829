import { useFormik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import axios  from 'axios';
import { Base_URL } from "../../../variable";

export default function AddUser() {
  const [initialValues, setInitialValues] = useState({
    username: "",
    password: "",
    phoneNumber: "",
    rePassword:'',
    role: "",
  });

  async function addUser(values) {
    const token = localStorage.getItem('Gtkn');
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json' // Ensuring correct content type
        }
    };
    const { data } = await axios.post(
      `${Base_URL}/api/Account/Register`,
      values,config
    );
    console.log("Response data:", data);
    if(data.message=="User Name already in use.")return data.message
    if (data.message === "Success") {
      console.log("success");
      return data.message;
    }
    return false;
  }

  const navigate = useNavigate();
  let formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: async function (values) {
      console.log(values);
      try {
        $(".btn").attr("disabled", "true");
        await addUser(values);
        $(" .successMsg").fadeIn(500, function () {
          setTimeout(function () {
            navigate("/AllUsers");
            $(".btn").removeAttr("disabled");
          }, 2000);
        });
     
      } catch (error) {
        console.log(error.response.data.message);
        if(error.response.data.message=="User Name already in use."){
          $(".userMsg").fadeIn(500, function () {
            setTimeout(function () {
              $(".userMsg").fadeOut(500);
              $(".btn").removeAttr("disabled");
            }, 1000);
          });
        }
        else{
          $(".errMsg").fadeIn(500, function () {
            setTimeout(function () {
              $(".errMsg").fadeOut(500);
              $(".btn").removeAttr("disabled");
            }, 1000);
          });
        }
      }
    },
    validate: function () {
      const errors = {};
      if (!formik.values.username.match(/^[a-zA-Z0-9]+$/)) {
        errors.username = "Name must contain only alphabets and numbers";
      }
      if (formik.values.password !== formik.values.rePassword) {
        errors.rePassword = "Passwords do not match";
      }
      if(!formik.values.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d]).{6,}$/)){
        errors.password="Password must contain at least upper letters, lower letter ,number and symbol";
      }
      if (!formik.values.phoneNumber.match(/^(?:\+20)?01[0125][0-9]{8}$/)) {
        errors.phoneNumber = "Invalid phone Number";
      }
      return errors;
    },
  });

  return (
    <>
      <div
        className="main-image d-flex justify-content-center align-items-center "
        style={{ minHeight: "100vh" }}
      >
        <div className="col-md-6 d-flex justify-content-center align-items-center  p-5 bg-second-color rounded rounded-5">
          <div className="item left-part container pt-5">
            <div className="">
              <div className="manage-alert">
                <div
                  className="errMsg alert text-danger"
                  role="alert"
                  style={{ display: "none" }}
                >
                  Failed to add..
                </div>
                <div
                  className="userMsg alert text-danger"
                  role="alert"
                  style={{ display: "none" }}
                >
                  User Name already in use 
                </div>
                <div
                  className="successMsg alert text-success"
                  role="alert"
                  style={{ display: "none" }}
                >
                  added !
                </div>
              </div>
              <h2 className="text-center head-color py-3">Add User</h2>
              <div className="container">
                <form
                  className="g-4 text-center mt-5"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="form-group">
                    <input
                      id="username"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.username}
                      type="text"
                      name="username"
                      placeholder="User Name"
                      className={`form-control ${
                        formik.errors.username && formik.touched.username
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    <div className="invalid-feedback">
                      {formik.errors.username}
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      id="phoneNumber"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phoneNumber}
                      type="text"
                      name="phoneNumber"
                      placeholder="Phone Number"
                      className={`form-control ${
                        formik.errors.phoneNumber && formik.touched.phoneNumber
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    <div className="invalid-feedback">
                      {formik.errors.phoneNumber}
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      id="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      type="password"
                      name="password"
                      placeholder="Password"
                      className={`form-control ${
                        formik.errors.password && formik.touched.password
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    <div className="invalid-feedback">
                      {formik.errors.password}
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      id="rePassword"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.rePassword}
                      type="password"
                      name="rePassword"
                      placeholder="Repassword"
                      className={`form-control ${
                        formik.errors.rePassword && formik.touched.rePassword
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    <div className="invalid-feedback">
                      {formik.errors.rePassword}
                    </div>
                  </div>
                  <div className="form-group py-2">
                    <div className="row">
                      <div className="col">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="role"
                            id="roleUser"
                            value="User"
                            onChange={formik.handleChange}
                            checked={formik.values.role === "User"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="roleUser"
                          >
                            User
                          </label>
                        </div>
                      </div>

                      <div className="col">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="role"
                            id="roleAdmin"
                            value="Admin"
                            onChange={formik.handleChange}
                            checked={formik.values.role === "Admin"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="roleAdmin"
                          >
                            Admin
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn bg-btn text-white mt-3 col-lg-3"
                    type="submit"
                  >
                    Add
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
