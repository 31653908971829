import axios from 'axios';
import { contains, param } from 'jquery'
import React, { useEffect, useState } from 'react'
import { Base_URL } from '../../../variable';
import { Link } from 'react-router-dom';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';

export default function AllTemplates() {
    const [Templates, setTemplates] = useState([]);
    async function GetTemplates() {
        try { 
            const { data } = await axios.get(`${Base_URL}/api/Template/AllTemplates`);
            if (data.message === "Success") {
                console.log("success"); 
                setTemplates(data.result)
            }
        } catch (error) {
            console.error("Request error:", error.response ? error.response.data : error);
        }
      }

      useEffect(() => {
        GetTemplates();
      }, [])
      
      async function DeleteTemplate(id){
            try {
                const token = 
                localStorage.getItem('Gtkn');
              
              const config = {
                  headers: { Authorization: `Bearer ${token}` },
                  params:{"TemplateId":id}
              };
                const {data}=await axios.delete(`${Base_URL}/api/Template/Template`,config);
              console.log(data);
              if(data.message=="Success")
             await GetTemplates();
            } catch (error) {
                console.log(error);
            }
      }

  return (
    <>
     <div className='main-image d-flex justify-content-center align-items-center ' style={{ minHeight: '100vh' }}>
        <div className="container mt-5 ">
            {Templates ? (
                <div className="table-responsive bg-second-color rounded rounded-2">
                    <table className="table table-bordered table-hover bg-transparent rounded rounded-2 p-3">
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col">#ID</th>
                                <th scope="col">Message</th>
                                <th scope="col">Manage</th>
                                <th scope="col">Delete</th>

                            </tr>
                        </thead>
                        <tbody>
                            {Templates.map(template => (
                                <tr key={template.id}>
                                    <th scope="row">{template.id}</th>
                                    <td>{template.message}</td>
                                    <td><Link to={`/ManageTemplate/${template.id}`}><button className='btn bg-btn text-white'>Manage</button></Link></td>
                                    <td><button className='btn bg-danger text-white' onClick={function(){
                                        DeleteTemplate(template.id);
                                    }}>Delete</button></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Link to='/AddTemplate'><button className='btn bg-btn text-white m-3' >Add Template</button></Link>
                </div>
            ) : (
                <LoadingScreen></LoadingScreen>
            )}
        </div>
        </div>
    </>
  )
}
