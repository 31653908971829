import React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from '../Navbar/Navbar'

export default function Layout({deleteUser,user,role}) {
  return (
    <>
    <div> 
       <Navbar deleteUser={deleteUser} user={user} role={role}/>
         <Outlet/>
          
        </div>
    </>
  )
}
