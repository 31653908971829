import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import $ from "jquery";
import { Base_URL } from '../../../variable';
export default function ManageTemplate() {
    const {Id}=useParams();
    const [initialValues, setInitialValues] = useState({
        id:Id,
        message: '',
    });

    function containsArabic(text) {
        // Regular expression that matches Arabic characters
        const arabicRegex = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF]/;
        if(arabicRegex.test(text)){
          console.log("Input contains Arabic:", text);
          return "AR";
        }
        console.log("Input does not contain Arabic:", text);
        return "EN";
    }
    async function UpdateTemplate(values) {
        try {
            const token = localStorage.getItem('Gtkn');
            const config = {
                headers: { 
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json' // Ensuring correct content type
                }
            };
       var language=containsArabic(values.message)
            // Directly using values as the body since the backend expects the DTO object at the top level
            const body = {
                ...values,
                lang:language
                // Making sure UserId is a string if it's not already
            };
    
            console.log("Sending request with body:", body);
    
            const { data } = await axios.put(`${Base_URL}/api/Template/Template`, body, config);
            console.log("Response data:", data);
    
            if (data.message === "Success") {
                console.log("success");
                // Additional success handling here
            }
        } catch (error) {
            console.error("Request error:", error.response ? error.response.data : error);
        }
    }
    
    
    const navigate = useNavigate();
    let formik = useFormik({
        enableReinitialize: true,
      initialValues: initialValues,
      onSubmit: async function (values) {
        console.log(values);
        try {
          $(".btn").attr("disabled", "true");
          await UpdateTemplate(values);
          $(" .successMsg").fadeIn(500, function () {
            setTimeout(function () {
              navigate("/AllTemplates");
             
              $(".btn").removeAttr("disabled");
            }, 2000);
          });
        } catch (error) {
          console.log(error.response.data.message);
          $(".errMsg").fadeIn(500, function () {
            setTimeout(function () {
              $(".errMsg").fadeOut(500);
              $(".btn").removeAttr("disabled");
            }, 1000);
          });
        }
      },
      validate: function () {
        const errors = {};
        if(containsArabic(formik.values.message)=="AR")
        {
          if(formik.values.message.length>70)
          errors.message="Arabic message must be less than 70"
        }
         if(containsArabic(formik.values.message)=="EN")
        {
          if(formik.values.message.length>120)
          errors.message="English message must be less than 120"
        }
  
        return errors;
      },
    });

    useEffect(() => {
        async function GetUserDetails() {
          try {
            const token = localStorage.getItem("Gtkn");
            const config = {
              headers: { Authorization: `Bearer ${token}` },
              params: { TemplateId: Id },
            };
            const { data } = await axios.get(
              `${Base_URL}/api/Template/Template?`,
              config
            );

            if (data.message === "Success" && data.result) {
              console.log("Success Details", data.result);
              //  setFieldValue('NewUsername', 'John');
              setInitialValues({
                id: data.result.id,
                message: data.result.message,
              });
            }
          } catch (error) {
            console.error(error);
          }
        }
        GetUserDetails();
      }, [Id]);
  return (
    <>

    <div className='main-image  d-flex justify-content-center align-items-center ' style={{ minHeight: '100vh' }}>
    <div className="col-md-6 d-flex justify-content-center align-items-center  p-5 bg-second-color rounded rounded-5">
            <div className="item left-part container pt-5">
            <div className="">
            
            <div className="manage-alert">
             
             <div className="errMsg alert text-danger" role="alert" style={{ display: "none" }}>
        Failed to update..
       </div>
       <div className="successMsg alert text-success" role="alert" style={{ display: "none" }}>
        Updated !
       </div>
             </div>
             <h2 className="text-center head-color py-3">Edit Template Message</h2>
          <div className="container">
          <form className="g-4 text-center mt-5" onSubmit={formik.handleSubmit}>
             <div className="form-group">
              
               <input
                 id="message"
                 onChange={formik.handleChange}
                 onBlur={formik.handleBlur}
                 value={formik.values.message}
                 type="text"
                 name="message"
                 placeholder="message"
                 className={`form-control ${formik.errors.message && formik.touched.message ? "is-invalid" : ""}`}
               />
               <div className="invalid-feedback">{formik.errors.message}</div>
             </div>
            
             <button className="btn bg-btn text-white mt-3 col-lg-3" type="submit">Update</button>
           </form>
           
          </div>
            </div>
            </div>
         </div>
    </div>
    </>
  )
}
