import axios from "axios";
import { Button } from "bootstrap/dist/js/bootstrap.bundle";
import { useFormik } from "formik";
import React from "react";
import { Navigate, useNavigate, useSubmit } from "react-router-dom";
import './login.css'
import $ from "jquery";
import { Base_URL } from "../../../variable";
function Login({crruser,getUserData}) {
  let user = {
    username: "",
    password: "",
  };
  async function signin(user) {
    const {data} = await axios.post(
     `${Base_URL}/api/Account/login`,
      user
    );
    if (data.message == "Success") {
      localStorage.setItem("Gtkn", data.result.token);
    }
    console.log(data);
  }
  const navigate = useNavigate();
  let formik = useFormik({
    initialValues: user,
    onSubmit: async function (values) {
      console.log(values);
      try {
        $(".btn").attr("disabled", "true");
        await signin(values);
        $(" .successMsg").fadeIn(500, function () {
          setTimeout(function () {
            navigate("/home");
            getUserData();
            $(".btn").removeAttr("disabled");
          }, 2000);
        });
      } catch (error) {
        console.log(error.response.data.message);
        $(".errMsg").fadeIn(500, function () {
          setTimeout(function () {
            $(".errMsg").fadeOut(500);
            $(".btn").removeAttr("disabled");
          }, 1000);
        });
      }
    },
    validate: function () {
      const errors = {};

     if(formik.values.username=='')errors.username="You must enter your name"
     if(formik.values.password=='')errors.password="You must enter your name"

      return errors;
    },
  });
  return (
   <div id="login" className="all">
     <div className="vh-100 d-flex justify-content-center align-items-center " >
    <div className="container  ">

      <div className="row ">
        <div className="col-md-6 d-flex justify-content-center align-items-center  pt-5">
           <div className="item left-part container pt-5">
           <div className="">
            <div className="gvlogo  text-center">
                <img src={require('../../../Images/logo.png')} alt="GV logo" className="img-fluid mb-2"/>
            </div>
           <div className="login-alert">
            
            <div className="errMsg alert text-danger" role="alert" style={{ display: "none" }}>
        Login failed. Incorrect name or password!
      </div>
      <div className="successMsg alert text-success" role="alert" style={{ display: "none" }}>
        Welcome Back !
      </div>
            </div>
            <h2 className="text-center head-color py-3">Welcome Back</h2>
         <div className="container">
         <form className="g-4 text-center mt-5" onSubmit={formik.handleSubmit}>
            <div className="form-group">
              <input
                id="username"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.username}
                type="text"
                name="username"
                placeholder="User Name"
                className={`form-control ${formik.errors.username && formik.touched.username ? "is-invalid" : ""}`}
              />
              <div className="invalid-feedback">{formik.errors.username}</div>
            </div>
            <div className="form-group">
           
              <input
                id="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                type="password"
                name="password"
                placeholder="Password"
                className={`form-control  ${formik.errors.password && formik.touched.password ? "is-invalid" : ""}`}
              />
              <div className="invalid-feedback">{formik.errors.password}</div>
            </div>
            <button className="btn bg-btn text-white mt-3 col-lg-3" type="submit">Sign in</button>
          </form>
          <div className="genesislogo text-center pt-5 mt-5">
          <img src={require('../../../Images/pwd.png')} alt="logo genesis" className="img-fluid"  />
          </div>
         </div>
           </div>
           </div>
        </div>
        <div className="col-md-6">
         <div className="item">
         <div className="item right-part w-100 rounded rounded-4" >
          <img src={require('../../../Images/nl.png')} alt="Jewelry" className="img-fluid w-100 "  /> 
         </div>
         </div>
        </div>
      </div>
    </div>
  </div>
   </div>

  );
}

export default Login;
