import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Base_URL } from '../../../variable';
import { Link } from 'react-router-dom';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';

export default function AllUsers() {
    const [users, setusers] = useState(null);

    async function GetAllUsers(){
        try {
          const token = 
          localStorage.getItem('Gtkn');
        
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
          const {data}=await axios.get(`${Base_URL}/api/Account/AllUsers`,config);
        console.log(data);
        if(data.message=="Success")
        setusers(data.result)
        } catch (error) {
          console.log(error);
        }
    }
    async function DeleteUser(id){
        try {
            const token = localStorage.getItem('Gtkn');
          const Id=id.toString();
          const config = {
              headers: { Authorization: `Bearer ${token}` },
    
          };
            const {data}=await axios.put(`${Base_URL}/api/Account/disable/${Id}`,config
            );
          console.log(data);
          if(data.message=="Success")
         await GetAllUsers();
        } catch (error) {
            console.log(error);
        }
  }

    async function DeleteUser2(id){
        try {
            const token = 
            localStorage.getItem('Gtkn');
          let Id=id.toString();
          console.log((Id));
          const config = {
              headers: { Authorization: `Bearer ${token}` },
              params:{"userId":Id}
          };
            const {data}=await axios.put(`${Base_URL}/api/Account/disable`,config);
          console.log(data);
          if(data.message=="Success")
         await GetAllUsers();
        } catch (error) {
            console.log(error);
        }
  }

    useEffect(
        function(){
            try {
                GetAllUsers();
             } catch (error) {
               console.log(error);
             }
        }
        , [])
    

  return (
    <>

        <div className='main-image  d-flex justify-content-center align-items-center ' style={{ minHeight: '100vh' }}>
        <div className="container mt-5 ">
            {users ? (
                <div className="table-responsive bg-second-color rounded rounded-2">
                    <table className="table table-bordered table-hover bg-second-color rounded rounded-2 p-3" >
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col">#ID</th>
                                <th scope="col">User Name</th>
                                <th scope="col">Role</th>
                                <th scope="col">Manage</th>
                                <th scope="col">Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map(user => (
                                <tr key={user.id}>
                                    <th scope="row">{user.id}</th>
                                    <td>{user.userName}</td>
                                    <td>{user.roles.join(', ') || 'None'}</td>
                                    <td><Link to={`/ManageUser/${user.id}`}><button className='btn bg-btn text-white'>Manage</button></Link></td>
                                    <td>
                                        {user.isEnabled?<button className='btn bg-danger text-white' onClick={function(){
                                        DeleteUser(user.id);
                                    }}>Delete</button>:"No longer exists"}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Link to='/AddUser'><button className='btn bg-btn text-white m-3' >Add User</button></Link>
                </div>
            ) : (
                <LoadingScreen></LoadingScreen>
            )}
        </div>
        </div>
    
    </>
  )
}
