import axios from "axios";
import React, { useEffect, useState } from "react";
import { Base_URL } from "../../../variable";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import $, { param } from "jquery";

export default function ManageUser() {
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState({
    UserId: id,
    NewUsername: "",
    Role: "",
  });

  async function UpdateUser(values) {
    try {
      const token = localStorage.getItem("Gtkn");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Ensuring correct content type
        },
      };

      // Directly using values as the body since the backend expects the DTO object at the top level
      const body = {
        ...values,
        UserId: values.UserId.toString(), // Making sure UserId is a string if it's not already
      };
      console.log("Sending request with body:", body);
      const { data } = await axios.put(
        `${Base_URL}/api/Account/updateUser`,
        body,
        config
      );
      console.log("Response data:", data);

      if (data.message === "Success") {
        console.log("success");
        // Additional success handling here
      }
    } catch (error) {
      console.error(
        "Request error:",
        error.response ? error.response.data : error
      );
    }
  }

  const navigate = useNavigate();
  let formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: async function (values) {
      console.log(values);
      try {
        $(".btn").attr("disabled", "true");
        await UpdateUser(values);
        $(" .successMsg").fadeIn(500, function () {
          setTimeout(function () {
            navigate("/AllUsers");

            $(".btn").removeAttr("disabled");
          }, 2000);
        });
      } catch (error) {
        console.log(error.response.data.message);
        $(".errMsg").fadeIn(500, function () {
          setTimeout(function () {
            $(".errMsg").fadeOut(500);
            $(".btn").removeAttr("disabled");
          }, 1000);
        });
      }
    },
    validate: function () {
      const errors = {};
      if (!formik.values.NewUsername.match(/^[a-zA-Z0-9]+$/)) {
        errors.NewUsername = "Name must contain only alphabets and numbers";
      }

      return errors;
    },
  });

  useEffect(() => {
    async function GetUserDetails() {
      try {
        const token = localStorage.getItem("Gtkn");
        const config = {
          headers: { Authorization: `Bearer ${token}` },
          params: { UserId: id },
        };
        const { data } = await axios.get(
          `${Base_URL}/api/Account/user`,
          config
        );
        if (data.message === "Success" && data.result) {
          console.log("Success Details", data.result);
          //  setFieldValue('NewUsername', 'John');
          setInitialValues({
            UserId: data.result.id,
            NewUsername: data.result.userName,
            Role: data.result.roles[0], // Assuming first role is primary
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
    GetUserDetails();
  }, [id]);
  return (
    <>
      <div
        className="main-image  d-flex justify-content-center align-items-center "
        style={{ minHeight: "100vh" }}
      >
        <div className="col-md-6 d-flex justify-content-center align-items-center  p-5 bg-second-color rounded rounded-5">
          <div className="item left-part container pt-5">
            <div className="">
              <div className="manage-alert">
                <div
                  className="errMsg alert text-danger"
                  role="alert"
                  style={{ display: "none" }}
                >
                  Failed to update..
                </div>
                <div
                  className="successMsg alert text-success"
                  role="alert"
                  style={{ display: "none" }}
                >
                  Updated !
                </div>
              </div>
              <h2 className="text-center head-color py-3">
                Update User Details
              </h2>
              <div className="container">
                <form
                  className="g-4 text-center mt-5"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="form-group">
                    <input
                      id="NewUsername"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.NewUsername}
                      type="text"
                      name="NewUsername"
                      placeholder="NewUsername"
                      className={`form-control ${
                        formik.errors.NewUsername && formik.touched.NewUsername
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    <div className="invalid-feedback">
                      {formik.errors.NewUsername}
                    </div>
                  </div>
                  <div className="form-group py-2">
                    <div className="row">
                      <div className="col">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="Role"
                            id="roleUser"
                            value="User"
                            onChange={formik.handleChange}
                            checked={formik.values.Role === "User"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="roleUser"
                          >
                            User
                          </label>
                        </div>
                      </div>

                      <div className="col">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="Role"
                            id="roleAdmin"
                            value="Admin"
                            onChange={formik.handleChange}
                            checked={formik.values.Role === "Admin"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="roleAdmin"
                          >
                            Admin
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <button
                    className="btn bg-btn text-white mt-3 col-lg-3"
                    type="submit"
                  >
                    Update
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
