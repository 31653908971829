import { Field, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import $, { param } from "jquery";
import axios from "axios";
import { Base_URL } from "../../variable";
export default function Home({ role }) {
  let SMS = {
    message: "",
    allNumbers: [],
    templateId: null,
    Lang: "EN",
  };
  const [Templates, setTemplates] = useState([]);
  const [ValidSMSNumbers, setValidSMSNumbers] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  //validte Numbers
  const [textAreaValue, setTextAreaValue] = useState("");
  const [invalidResults, setInvalidResults] = useState([]);
  function containsArabic(text) {
    // Regular expression that matches Arabic characters
    const arabicRegex =
      /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF]/;
    if (arabicRegex.test(text)) {
      console.log("Input contains Arabic:", text);
      return "AR";
    }
    console.log("Input does not contain Arabic:", text);
    return "EN";
  }

  const phoneNumberRegex = /^01[0-2,5]{1}[0-9]{8}$/;
  const validatePhoneNumbers = () => {
    const lines = textAreaValue.split("\n");
    // Filter and map lines to get only invalid numbers
    const invalidNumbers = lines
      .map((line, index) => ({
        line: index + 1,
        number: line.trim(),
        isValid: phoneNumberRegex.test(line.trim()),
      }))
      .filter((result) => !result.isValid); // Keep only invalid results
    // Filter out valid numbers
    const validNumbers = lines
      .map((line) => line.trim()) // Trim each line to ensure we're checking clean data
      .filter((line) => phoneNumberRegex.test(line));

    setInvalidResults(invalidNumbers);
    setValidSMSNumbers(validNumbers);
    //  console.log(validNumbers);
    // console.log("Valid");
    return validNumbers;
  };

  //Get Templates
  async function GetTemplates(values) {
    try {
      const { data } = await axios.get(`${Base_URL}/api/Template/AllTemplates`);

      if (data.message === "Success") {
        console.log("success");
        setTemplates(data.result);
      }
    } catch (error) {
      console.error(
        "Request error:",
        error.response ? error.response.data : error
      );
    }
  }

  //handle Templates
  const handleSelectTemplate = (message, id) => {
    setCurrentMessage(message);
    formik.setFieldValue("message", message);
    formik.setFieldValue("templateId", id);
  };

  async function SendMessage(values) {
    try {
      const token = localStorage.getItem("Gtkn");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Ensuring correct content type
        },
      };

      console.log("Sending request with body:", values);

      const { data } = await axios.post(
        `${Base_URL}/api/Message/SendMessage`,
        values,
        config
      );
      console.log("Response data:", data);

      if (data.message === "Success") {
        console.log("success");
        // Additional success handling here
      }
    } catch (error) {
      console.error(
        "Request error:",
        error.response ? error.response.data : error
      );
    }
  }

  //handle form
  let formik = useFormik({
    enableReinitialize: true,
    initialValues: SMS,
    onSubmit: async function (values) {
      validatePhoneNumbers();
      SMS = {
        ...values,
        allNumbers: validatePhoneNumbers(),
        Lang: containsArabic(values.message),
      };
      console.log(SMS);
      //  console.log(values);
      try {
        $(".btn").attr("disabled", "true");
        await SendMessage(SMS);
        $(".success").fadeIn(500, function () {
          setTimeout(function () {
            $(".btn").removeAttr("disabled");
            formik.resetForm();
            $(".success").fadeOut(500);
          }, 2000);
        });
      } catch (error) {
        console.log(error.response.data.message);
        $(".errMsg").fadeIn(500, function () {
          setTimeout(function () {
            $(".errMsg").fadeOut(500);
            $(".btn").removeAttr("disabled");
          }, 1000);
        });
      }
    },
    validate: function () {
      const errors = {};
      if (formik.values.message == "")
        errors.message = "Enter the content of the message";
      var VNum = validatePhoneNumbers();
      if (VNum.length == 0) errors.allNumbers = "Enter valid Phone Numbers";

      return errors;
    },
  });

  useEffect(() => {
    GetTemplates();
  }, []);

  return (
    <>
      <div
        className="main-image vh-100 d-flex justify-content-center align-items-center "
        id=""
      >
        <div className="container py-5 ">
          <div>
            <div className="manage-alert">
              <div
                className="errMsg alert text-danger"
                role="alert"
                style={{ display: "none" }}
              >
                Failed to send..
              </div>
              <div
                className="successMsg alert text-success"
                role="alert"
                style={{ display: "none" }}
              >
                Message Sent Successfully !
              </div>
            </div>
            <div style={{display:"none"}} className="success">
              <div
                className=" text-center bg-main-color bg-opacity-25 d-flex justify-content-center align-items-center py-4 rounded rounded-2 px-5  "
                style={{
                  position: "fixed", // Use 'fixed' instead of 'absolute' for viewport-level positioning
                  top: "50%", // Center vertically
                  left: "50%", // Center horizontally
                  transform: "translate(-50%, -50%)", // Adjust for exact centering
                  zIndex: "9999", // Ensure it's above all other content
                  width: "auto", // Adjust width as needed
                  maxWidth: "90%", // Prevent it from being too wide on small screens
                }}
              >
                <div>
                  <div
                    className="mb-2 text-third-color"
                    style={{ fontSize: "2rem", fontWeight: "bold" }}
                  >
                    Message Sent Successfully !
                  </div>
                  <div
                    className="text-third-color"
                    style={{ fontSize: "4rem" }}
                  >
                    <i className="fa-solid fa-check"></i>
                  </div>
                </div>
              </div>
            </div>

            <h1 className="text-color text-center mb-5">Messaging Solution</h1>
            <div className="d-flex justify-content-center">
              <div className="" id="areas">
                <form onSubmit={formik.handleSubmit} className="">
                  <div className="bg-second-color rounded rounded-3 p-5">
                    <div className="numbers ">
                      <div className="">
                        <div className="item">
                          <textarea
                            className={`bg-second-color rounded rounded-2 me-3  ${
                              formik.errors.allNumbers &&
                              formik.touched.allNumbers
                                ? "is-invalid"
                                : ""
                            }`}
                            value={textAreaValue}
                            onChange={(e) => setTextAreaValue(e.target.value)}
                            placeholder="Enter one phone number per line"
                          ></textarea>
                          <div className="invalid-feedback">
                            {formik.errors.allNumbers}
                          </div>
                        </div>
                      </div>

                      <div className="">
                        <button
                          onClick={validatePhoneNumbers}
                          className="btn bg-btn text-white mt-3 ms-2"
                          type="button"
                        >
                          Validate Numbers
                        </button>
                        <div>
                          {invalidResults.length > 0 ? (
                            <div className="ms-2">
                              <h6 className="text-third-color">
                                Invalid Numbers:
                              </h6>
                              {invalidResults.map((result) => (
                                <div key={result.line}>
                                  Line {result.line}: {result.number}
                                </div>
                              ))}
                            </div>
                          ) : invalidResults.length == 0 &&
                            ValidSMSNumbers.length > 0 ? (
                            <p>All numbers are valid.</p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="templates py-3">
                      <div class="dropdown">
                        <button
                          class="btn bg-second-color dropdown-toggle text-secondary"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Quick Reply
                        </button>
                        <ul class="dropdown-menu">
                          {Templates?.map((template) => (
                            <li key={template.id}>
                              <button
                                className="dropdown-item"
                                type="button"
                                onClick={() =>
                                  handleSelectTemplate(
                                    template.message,
                                    template.id
                                  )
                                }
                              >
                                {template.message}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="message mt-2">
                        <textarea
                          name="message"
                          id="message"
                          disabled={role === "User"}
                          placeholder={
                            role === "User"
                              ? "Users are not allowed to write here"
                              : "Write your message"
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.message}
                          className={` bg-second-color rounded rounded-2 w-50 ${
                            formik.errors.message && formik.touched.message
                              ? "is-invalid"
                              : ""
                          }`}
                        ></textarea>
                        <div className="invalid-feedback">
                          {formik.errors.message}
                        </div>
                      </div>
                      <button
                        className="btn bg-btn text-white mt-3 "
                        type="submit"
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
