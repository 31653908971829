import React from 'react'

export default function LoadingScreen() {
    return (
        <div className='vh-100 text-center bg-main-color bg-opacity-25 d-flex justify-content-center align-items-center'>
            <div className="loading">
   <i className='fa-solid fa-spin fa-spinner fa-5x text-color'></i>
            </div>
        </div>
    );
}
