import React, { useEffect, useState } from 'react';
import { Base_URL } from '../../variable';
import axios from 'axios';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import ReactPaginate from 'react-paginate';
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Image } from '@react-pdf/renderer';

export default function SMSReport() {
    const [report, setReport] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage] = useState(10); // Number of items per page

    async function getReport() {
        try {
            const { data } = await axios.get(`${Base_URL}/api/Message/Report`);
            if (data.message === "Success") {
                setReport(data.result);
            }
        } catch (error) {
            console.error("Request error:", error.response ? error.response.data : error);
        }
    }

    useEffect(() => {
        getReport();
    }, []);

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };

    const offset = currentPage * perPage;
    const pageCount = Math.ceil((report?.length || 0) / perPage);

    // Define styles for your PDF document
    const styles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            backgroundColor: '#926b63',
            padding: 10,
        },
        table: {
            display: "table",
            width: "auto",
            borderStyle: "solid",
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0,
        },
        tableRow: {
            margin: "auto",
            flexDirection: "row",
        },
        tableColHeader: {
            width: "20%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            backgroundColor: '#8D645C',
        },
        tableCol: {
            width: "20%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
        },
        tableCellHeader: {
            margin: "auto",
            marginTop: 5,
            marginBottom: 5,
            fontSize: 10, // Set the font size for table headers
        },
        tableCell: {
            margin: "auto",
            marginTop: 5,
            marginBottom: 5,
            fontSize: 8, // Set the font size for table cells
        },
        image: {
            margin: 10, // Adjust as needed
            width: 150, // Set the width of your image
            height: 100, // Set the height of your image
        },
        Text:{
         margin:10,
         color:'#3E2D3F'
        },
    
    });
    const now = new Date();
    const reportGeneratedTime = now.toLocaleString();
    // Define the PDF Document structure
    const MyDocument = () => (
        <Document>
            <Page size="A4" style={styles.page}>
            <Image
                style={styles.image}
                src={require('../../Images/logo.png')} // Replace with your image source
            />
             <Text style={styles.Text}>Report generated on: {reportGeneratedTime}</Text>
             <Text style={styles.Text}>This document serves as a report of SMS content and its details.</Text>
                <View style={styles.table}>
                    {/* Table Header */}
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>#ID</Text></View>
                        <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>Sender Name</Text></View>
                        <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>Phone Number</Text></View>
                        <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>Message</Text></View>
                        <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>User</Text></View>
                    </View>
                    {/* Table Rows */}
                    {report.slice(offset, offset + perPage).map((template, index) => (
                        <View key={index} style={styles.tableRow}>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>{template.id}</Text></View>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>{template.sendername}</Text></View>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>{template.mobiles}</Text></View>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>{template.message}</Text></View>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>{template.appUser}</Text></View>
                        </View>
                    ))}
                </View>
            </Page>
        </Document>
    );


    return (
        <>
            <div className='main-image d-flex justify-content-center align-items-center ' style={{ minHeight: '100vh' }}>
                <div className="container mt-5 ">

                    {report ? (
                        <>
                         <PDFDownloadLink document={<MyDocument />} fileName="report.pdf" className='btn bg-btn text-white mb-2'>
                                {({ blob, url, loading, error }) => (loading ? 'Preparing document...' : 'Download Report')}
                            </PDFDownloadLink>

                            <div className="table-responsive bg-second-color rounded rounded-2">
                                <table className="table table-bordered table-hover bg-transparent rounded rounded-2 p-3">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th scope="col">#ID</th>
                                            <th scope="col">Sender Name</th>
                                            <th scope="col">Phone Number</th>
                                            <th scope="col">Message</th>
                                            <th scope="col">User</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {report.slice(offset, offset + perPage).map(template => (
                                            <tr key={template.id}>
                                                <th scope="row">{template.id}</th>
                                                <td>{template.sendername}</td>
                                                <td>{template.mobiles}</td>
                                                <td>{template.message}</td>
                                                <td>{template.appUser}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className=' text-color'>
                                <span>Number of messages sent : </span>
                                <span>{report.length}</span>
                            </div>
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                pageCount={pageCount}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                previousLinkClassName={"page-link"}
                                nextLinkClassName={"page-link"}
                                disabledClassName={"disabled"}
                                activeClassName={"active"}
                            />
                        </>
                    ) : (
                        <LoadingScreen />
                    )}
                </div>
            </div>
        </>
    );
}
