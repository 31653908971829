import React, { useEffect, useState } from 'react';
import { Base_URL } from '../../variable';
import axios from 'axios';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import ReactPaginate from 'react-paginate';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export default function Report() {
    const [report, setReport] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage] = useState(10); // Number of items per page

    async function getReport() {
        try {
            const { data } = await axios.get(`${Base_URL}/api/Message/Report`);
            if (data.message === "Success") {
                setReport(data.result);
            }
        } catch (error) {
            console.error("Request error:", error.response ? error.response.data : error);
        }
    }

    useEffect(() => {
        getReport();
    }, []);

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };

    const offset = currentPage * perPage;
    const pageCount = Math.ceil((report?.length || 0) / perPage);

    const downloadPdfDocument = () => {
        const input = document.getElementsByClassName('main-image')[0];
        html2canvas(input)
          .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
              orientation: 'landscape',
            });
            const imgProps= pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save("download.pdf");
          });
      };

    return (
        <>
            <div className='main-image d-flex justify-content-center align-items-center ' style={{ minHeight: '100vh' }}>
                <div className="container mt-5 ">

                    {report ? (
                        <>
                        <button onClick={downloadPdfDocument} className='btn bg-btn text-white mb-2'><i className="fa-regular fa-file-pdf m-2"></i>Download Report</button>

                            <div className="table-responsive bg-second-color rounded rounded-2">
                                <table className="table table-bordered table-hover bg-transparent rounded rounded-2 p-3">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th scope="col">#ID</th>
                                            <th scope="col">Sender Name</th>
                                            <th scope="col">Phone Number</th>
                                            <th scope="col">Message</th>
                                            <th scope="col">User</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {report.slice(offset, offset + perPage).map(template => (
                                            <tr key={template.id}>
                                                <th scope="row">{template.id}</th>
                                                <td>{template.sendername}</td>
                                                <td>{template.mobiles}</td>
                                                <td>{template.message}</td>
                                                <td>{template.appUser}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className=' text-color'>
                                <span>Number of messages sent : </span>
                                <span>{report.length}</span>
                            </div>
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                pageCount={pageCount}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                previousLinkClassName={"page-link"}
                                nextLinkClassName={"page-link"}
                                disabledClassName={"disabled"}
                                activeClassName={"active"}
                            />
                        </>
                    ) : (
                        <LoadingScreen />
                    )}
                </div>
            </div>
        </>
    );
}
